import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { EColors } from 'styles/style-variables/colors';

import { SText } from 'components/styled/SText';

export const RequiredText = styled(SText)`
  position: absolute;
  right: 32px;
  bottom: 32px;
  display: inline-flex;
  gap: 4px;
  margin-left: auto;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-align: right;
  text-transform: uppercase;
  color: ${EColors.Gray2};

  @media (max-width: ${BreakPoint.MobileTop}) {
    right: auto;
  }

  &::before {
    content: '*';
    color: ${EColors.Red1};
  }
`;
