import React from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as CardIcon } from 'assets/icons/payment-options-icon/card.svg';
import { ReactComponent as ApplePayIcon } from 'assets/icons/payment-options-icon/card-pay-icons/apple-pay.svg';
import { ReactComponent as GooglePayIcon } from 'assets/icons/payment-options-icon/card-pay-icons/google-pay.svg';
import { ReactComponent as MasterCardIcon } from 'assets/icons/payment-options-icon/card-pay-icons/master-card.svg';
import { ReactComponent as VisaIcon } from 'assets/icons/payment-options-icon/card-pay-icons/visa.svg';
import { useUnloadStatus } from 'pages/PagePayment/hooks/useUnloadStatus';
import { EPaymentType } from 'store/payment-reducer/payment.reducer';
import { selectPaymentCardFetchStatus } from 'store/payment-reducer/payment.selectors';

import * as S from 'pages/PagePayment/components/ui/PaymentOption/PaymentOption.styled';

interface CardPaymentProps {
  t: (key: string) => string;
  handlePaymentButtonClick: (paymentType: EPaymentType) => void;
}

export const CardPaymentCard: React.FC<CardPaymentProps> = ({ t, handlePaymentButtonClick }) => {
  const paymentCardStatus = useSelector(selectPaymentCardFetchStatus);
  const { isLoading } = useUnloadStatus(paymentCardStatus);

  return (
    <S.CardWrapper>
      <S.CardContent>
        <S.CardTitleWrapper>
          {<CardIcon />}
          <S.CartTitle>{t('payment.cardPaymentTitle')}</S.CartTitle>
        </S.CardTitleWrapper>
        <S.CardText>{t('payment.cardPaymentInfoText')}</S.CardText>
        <S.IconsContainer $iconGap={12}>
          <VisaIcon />
          <MasterCardIcon />
          <ApplePayIcon />
          <GooglePayIcon />
        </S.IconsContainer>
      </S.CardContent>

      {isLoading ? (
        <S.SpinnerWrapper>
          <S.StyledSpinner />
        </S.SpinnerWrapper>
      ) : (
        <S.PayButton
          text={t('payment.cardPaymentTitle')}
          onClick={() => handlePaymentButtonClick(EPaymentType.Card)}
        />
      )}
    </S.CardWrapper>
  );
};
