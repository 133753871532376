import axios, { AxiosRequestConfig } from 'axios';
import { MINUTE_IN_SECONDS } from 'constants/time/minute-in-seconds';
import dayjs from 'dayjs';

import { deleteAuthToken } from 'utils/storage/auth-token/delete-auth-token';
import { getAuthToken } from 'utils/storage/auth-token/get-auth-token';
import { deleteWebimCookies } from 'utils/storage/webim-cookies/delete-webim-cookies';

import { AffiliateRoute } from './affiliate/affiliate.route';
// import { AssetsRoute } from './assets/assets.route';
import { DashboardRoute } from './dashboard/dashboard.route';
import { ProfileRoute } from './profile/profile.route';
import { PurchaseRoute } from './purchase/purchase.route';
import { RatesRoute } from './rates/rates.route';
// import { TransactionRoute } from './transaction/transaction.rout';

const cache = new Map();
const routesWithCashedRequests = [
  // DashboardRoute.DashboardWallet(),
  DashboardRoute.DashboardMiningHashRate(),
  DashboardRoute.DashboardMiningTotal(),
  // DashboardRoute.DashboardElectricity(),
  PurchaseRoute.Miners(),
  ProfileRoute.Profile(),
  ProfileRoute.Wallet(),
  RatesRoute.PairRates(),
  // AssetsRoute.Assets(),
  // TransactionRoute.TransactionHistory(10000, '01.01.2021'),
  AffiliateRoute.ReferralStats(),
  AffiliateRoute.ReferralHistory(),
  AffiliateRoute.ReferralLinks(),
];
const generateCacheKey = (config: AxiosRequestConfig) => {
  const { method, url, params, data } = config;
  return `${method}:${url}:${JSON.stringify(params)}:${JSON.stringify(data)}`;
};
export const cuverseApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  responseType: 'json',
});
cuverseApi.interceptors.request.use(async (req) => {
  const token = getAuthToken();
  if (token) {
    req.headers['Authorization'] = `Bearer ${token}`;
  }
  const shouldCache = routesWithCashedRequests.includes(req.url || '');
  if (!shouldCache) {
    return Promise.resolve(req);
  }
  const cacheKey = generateCacheKey(req);
  const cachedEntry = cache.get(cacheKey);
  if (cachedEntry) {
    const { lastFetched, data } = cachedEntry;
    const isCacheValid = dayjs().unix() - lastFetched < MINUTE_IN_SECONDS;
    if (isCacheValid) {
      return Promise.reject({ isCache: true, data });
    } else {
      cache.delete(cacheKey);
    }
  }
  return Promise.resolve(req);
});
cuverseApi.interceptors.response.use(
  (res) => {
    const cacheKey = generateCacheKey(res.config);
    cache.set(cacheKey, { data: res.data, lastFetched: dayjs().unix() });
    return res;
  },
  (error) => {
    if (error.response?.status === 401) {
      deleteAuthToken();
      deleteWebimCookies();
      window.location.reload();
      localStorage.clear();
    }
    if (error.isCache) {
      return Promise.resolve({ data: error.data });
    }
    return Promise.reject(error);
  },
);
