import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'store/store';

import { IPaymentState } from './payment.reducer';

export const getPaymentState = (state: TRootState): IPaymentState => state.paymentReducer;

export const selectPaymentResult = createSelector(
  [getPaymentState],
  (paymentState: IPaymentState) => paymentState.paymentStatusResult,
);

export const selectPaymentOrdersIds = createSelector(
  [getPaymentState],
  (paymentState: IPaymentState) => paymentState.ordersIds,
);

export const selectPaymentOuterLink = createSelector(
  [getPaymentState],
  (state) => state.paymentOuterLink,
);

export const selectPaymentCryptoFetchStatus = createSelector(
  [getPaymentState],
  (paymentState: IPaymentState) => paymentState.paymentCryptoFetchStatus,
);

export const selectPaymentCardFetchStatus = createSelector(
  [getPaymentState],
  (paymentState: IPaymentState) => paymentState.paymentCardFetchStatus,
);

export const selectBtcBalanceFetchStatus = createSelector(
  [getPaymentState],
  (paymentState: IPaymentState) => paymentState.btcBalanceFetchStatus,
);

export const selectPaymentError = createSelector(
  [getPaymentState],
  (paymentState: IPaymentState) => paymentState.error,
);

export const selectPaymentCategory = createSelector(
  [getPaymentState],
  (paymentState: IPaymentState) => paymentState.paymentCategory,
);
