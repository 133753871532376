import React from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as BnbIcon } from 'assets/icons/payment-options-icon/crypto-pay-icons/bnb.svg';
import { ReactComponent as EthIcon } from 'assets/icons/payment-options-icon/crypto-pay-icons/eth.svg';
import { ReactComponent as UsdcIcon } from 'assets/icons/payment-options-icon/crypto-pay-icons/usdc.svg';
import { ReactComponent as UsdtIcon } from 'assets/icons/payment-options-icon/crypto-pay-icons/usdt.svg';
import { ReactComponent as WalletIcon } from 'assets/icons/payment-options-icon/wallet.svg';
import { useUnloadStatus } from 'pages/PagePayment/hooks/useUnloadStatus';
import { ECryptoPaymentVariant, EPaymentType } from 'store/payment-reducer/payment.reducer';
import { selectPaymentCryptoFetchStatus } from 'store/payment-reducer/payment.selectors';

import * as S from 'pages/PagePayment/components/ui/PaymentOption/PaymentOption.styled';

interface CryptoPaymentProps {
  t: (key: string) => string;
  handlePaymentButtonClick: (
    paymentType: EPaymentType,
    cryptoPaymentVariant?: ECryptoPaymentVariant,
  ) => void;
}

export const CryptoPaymentCard: React.FC<CryptoPaymentProps> = ({
  handlePaymentButtonClick,
  t,
}) => {
  const paymentCryptoStatus = useSelector(selectPaymentCryptoFetchStatus);
  const { isLoading } = useUnloadStatus(paymentCryptoStatus);

  return (
    <S.CardWrapper>
      <S.CardContent>
        <S.CardTitleWrapper>
          <WalletIcon />
          <S.CartTitle>{t('payment.cryptoPaymentTitle')}</S.CartTitle>
        </S.CardTitleWrapper>
        <S.CardText>{t('payment.cryptoPaymentInfoText')}</S.CardText>
        <S.IconsContainer $iconGap={8}>
          <UsdtIcon />
          <UsdcIcon />
          <EthIcon />
          <BnbIcon />
          <S.IconText>{t('payment.andManyMore')}</S.IconText>
        </S.IconsContainer>
      </S.CardContent>
      {isLoading ? (
        <S.SpinnerWrapper>
          <S.StyledSpinner />
        </S.SpinnerWrapper>
      ) : (
        <S.ButtonsWrapper>
          <S.PayButton
            text={t('payment.cryptoPaymentAlphaPo')}
            onClick={() =>
              handlePaymentButtonClick(EPaymentType.Crypto, ECryptoPaymentVariant.AlphaPo)
            }
          />
          <S.PayButton
            text={t('payment.cryptoPaymentCoinsBuy')}
            onClick={() =>
              handlePaymentButtonClick(EPaymentType.Crypto, ECryptoPaymentVariant.CoinsBuy)
            }
          />
        </S.ButtonsWrapper>
      )}
    </S.CardWrapper>
  );
};
