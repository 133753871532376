import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getOperationTypeByCategory } from 'pages/PagePayment/utils/getOperationTypeByCategory';
import { paymentParamsAdaptor } from 'pages/PagePayment/utils/paymentParamsAdaptor';
import {
  HISTORY_TRANSACTIONS_COUNT,
  INITIAL_DATE,
} from 'pages/PageTransactionsHistory/constants/constants';
import {
  requestDashboardElectricity,
  requestDashboardWalletBalance,
} from 'store/dashboard-reducer/dashboard.thunk-actions';
import { selectInvoiceData } from 'store/invoice-reducer/invoice.selectors';
import {
  ECryptoPaymentVariant,
  EPaymentCategory,
  EPaymentMethod,
  EPaymentType,
  resetPaymentLinks,
} from 'store/payment-reducer/payment.reducer';
import {
  selectPaymentCategory,
  selectPaymentOuterLink,
} from 'store/payment-reducer/payment.selectors';
import { selectReplenishData } from 'store/replenish-reducer/replenish.selectors';
import { useAppDispatch } from 'store/store';
import { requestTransactionHistoryData } from 'store/transaction-reducer/transaction-history.thunk-actions';

import { gtmClickPaymentSubmit } from 'utils/gtmSender/gtmSender';

import { useCategoryPaymentRedirect } from './useCategoryPaymentRedirect';
import { useGmtPaymentPrepareEcommerce } from './useGmtPaymentPrepareEcommerce';
import { usePaymentAction } from './usePaymentAction';

interface IPaymentReturn {
  route: string;
  amount: number;
  paymentId: number | null;
  paymentCategory: EPaymentCategory | null;
  handlePaymentButtonClick: (
    paymentType: EPaymentType,
    cryptoPaymentVariant?: ECryptoPaymentVariant,
  ) => void;
}

/**
 * usePayment hook returns an object with the following properties:
 * - Route - URL that user will be redirected to after payment
 * - Amount of payment in BTC
 * - Payment ID
 * - Function that should be called when user clicks on the payment button
 */
export const usePayment = (): IPaymentReturn => {
  const dispatch = useAppDispatch();
  const invoiceData = useSelector(selectInvoiceData);
  const replenishData = useSelector(selectReplenishData);
  const paymentCategory = useSelector(selectPaymentCategory);
  const { btc } = useGmtPaymentPrepareEcommerce();
  const { route, replace } = useCategoryPaymentRedirect(paymentCategory);

  const paymentOuterLink = useSelector(selectPaymentOuterLink);

  const { amount, paymentId } = paymentParamsAdaptor(
    { replenishData, invoiceData },
    paymentCategory,
  );

  const { payment } = usePaymentAction();

  useEffect(() => {
    if (paymentOuterLink?.paymentLink) {
      window.location.href = paymentOuterLink.paymentLink;
      void dispatch(resetPaymentLinks());
    }
  }, [paymentOuterLink?.paymentLink, dispatch]);

  const handlePaymentButtonClick = useCallback(
    (paymentType: EPaymentType, cryptoPaymentVariant?: ECryptoPaymentVariant) => {
      if (!paymentId) return;
      const operationType = getOperationTypeByCategory(paymentCategory);
      if (operationType === null) return;

      const paymentHandlers: Record<EPaymentType, () => void> = {
        [EPaymentType.Balance]: async () => {
          payment(paymentId, EPaymentMethod.BtcBalance, operationType, route, replace);
          gtmClickPaymentSubmit(btc);
          await dispatch(requestDashboardElectricity());
          await dispatch(
            requestTransactionHistoryData({
              count: HISTORY_TRANSACTIONS_COUNT,
              dateStart: INITIAL_DATE,
            }),
          );
          await dispatch(requestDashboardWalletBalance());
        },
        [EPaymentType.Crypto]: () => {
          if (cryptoPaymentVariant === ECryptoPaymentVariant.CoinsBuy) {
            payment(paymentId, EPaymentMethod.CoinsBuy, operationType, route, replace);
            return;
          }

          if (cryptoPaymentVariant === ECryptoPaymentVariant.AlphaPo) {
            payment(paymentId, EPaymentMethod.AlphaPo, operationType, route, replace);
            return;
          }
        },
        [EPaymentType.Card]: () => {
          payment(paymentId, EPaymentMethod.Mercuryo, operationType, route, replace);
        },
      };

      paymentHandlers[paymentType]?.();
    },
    [paymentId, paymentCategory, payment, route, replace, btc, dispatch],
  );

  return {
    route,
    amount,
    paymentId,
    paymentCategory,
    handlePaymentButtonClick,
  };
};
