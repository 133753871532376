import React from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as BitcoinIcon } from 'assets/icons/payment-options-icon/bitcoin-icon.svg';
import { EPaymentType } from 'store/payment-reducer/payment.reducer';
import { selectBtcBalanceFetchStatus } from 'store/payment-reducer/payment.selectors';

import * as S from 'pages/PagePayment/components/ui/PaymentOption/PaymentOption.styled';

interface PaymentOptionBtcProps {
  handlePaymentButtonClick: (paymentType: EPaymentType) => void;
  balance: number;
  amount: number;
  t: (key: string) => string;
}

export const BtcPaymentCard: React.FC<PaymentOptionBtcProps> = (props) => {
  const { handlePaymentButtonClick, balance, amount, t } = props;
  const paymentStatus = useSelector(selectBtcBalanceFetchStatus);
  const unit = 'BTC';
  return (
    <S.CardWrapper>
      <S.CardTitleWrapper>
        <BitcoinIcon />
        <S.CartTitle>{t('payment.btcPaymentTitle')}</S.CartTitle>
      </S.CardTitleWrapper>
      <S.AmountInfo>
        <S.AmountContainer>
          <S.AmountTitle>{t('payment.onYourBalance')}</S.AmountTitle>
          <S.Amount>
            {balance} {unit}
          </S.Amount>
        </S.AmountContainer>
        <S.AmountContainer>
          <S.AmountTitle>{t('payment.youPay')}</S.AmountTitle>
          <S.AmountYouPay>
            {amount} {unit}
          </S.AmountYouPay>
        </S.AmountContainer>
      </S.AmountInfo>
      {paymentStatus === 'pending' ? (
        <S.SpinnerWrapper>
          <S.StyledSpinner />
        </S.SpinnerWrapper>
      ) : (
        <S.PayButton
          text={t('payment.btcPaymentTitle')}
          onClick={() => handlePaymentButtonClick(EPaymentType.Balance)}
        />
      )}
    </S.CardWrapper>
  );
};
