import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IInvoicesIds,
  IPaymentBtcResponseData,
  IPaymentMessageMapped,
} from 'store/api/cuverse-api/payment/types/payment-response.interface';
import { TFetchStatus } from 'types/api/fetch-status.type';

import { fetchPaymentThunkAction } from './payment.thunk-actions';

export const enum EPaymentCategory {
  Electricity = 'electricity',
  Cart = 'cart',
}

export enum EPaymentMethod {
  BtcBalance = 'btc-balance',
  AlphaPo = 'alpha-po',
  CoinsBuy = 'coinsbuy',
  Mercuryo = 'mercuryo',
}

export enum EPaymentType {
  Balance = 'balance',
  Crypto = 'crypto',
  Card = 'card',
}

export const enum ECryptoPaymentVariant {
  AlphaPo = 'AlphaPo',
  CoinsBuy = 'CoinsBuy',
}

export const enum EOperationType {
  PoolMiner = 'pool_miner',
  ElectricityFeePayment = 'electricity_fee_payment',
}

export interface IPaymentState {
  paymentStatusResult: string | null;
  paymentCategory: EPaymentCategory | null;
  ordersIds: IInvoicesIds[] | null;

  paymentOuterLink: IPaymentMessageMapped | null;

  btcBalanceFetchStatus: TFetchStatus;
  paymentCryptoFetchStatus: TFetchStatus;
  paymentCardFetchStatus: TFetchStatus;

  error: string | null;
}

const initialState: IPaymentState = {
  paymentStatusResult: null,
  paymentCategory: null,
  ordersIds: null,

  paymentOuterLink: null,

  btcBalanceFetchStatus: 'initial',
  paymentCryptoFetchStatus: 'initial',
  paymentCardFetchStatus: 'initial',

  error: null,
};

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setPaymentCategoryAction: (state, action: PayloadAction<EPaymentCategory>) => {
      state.paymentCategory = action.payload;
    },
    clearPaymentStateAction: (state) => ({
      ...initialState,
      paymentCategory: state.paymentCategory,
    }),
    resetPaymentLinks: (state) => {
      state.paymentOuterLink = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPaymentThunkAction.pending, (state, action) => {
      const { paymentMethod } = action.meta.arg;
      if (paymentMethod === 'btc-balance') {
        state.btcBalanceFetchStatus = 'pending';
      } else if (paymentMethod === 'alpha-po' || paymentMethod === 'coinsbuy') {
        state.paymentCryptoFetchStatus = 'pending';
      } else if (paymentMethod === 'mercuryo') {
        state.paymentCardFetchStatus = 'pending';
      }
      state.error = null;
    });
    builder.addCase(
      fetchPaymentThunkAction.fulfilled,
      (
        state,
        action: PayloadAction<IPaymentBtcResponseData | IPaymentMessageMapped> & {
          meta: { arg: { paymentMethod: EPaymentMethod } };
        },
      ) => {
        const { paymentMethod } = action.meta.arg;
        if (paymentMethod === 'btc-balance') {
          state.btcBalanceFetchStatus = 'fulfilled';
        } else if (paymentMethod === 'alpha-po' || paymentMethod === 'coinsbuy') {
          state.paymentCryptoFetchStatus = 'fulfilled';
        } else if (paymentMethod === 'mercuryo') {
          state.paymentCardFetchStatus = 'fulfilled';
        }

        if (action.payload) {
          if (typeof action.payload === 'object' && 'status' in action.payload) {
            const { status, data } = action.payload;
            state.paymentStatusResult = status;
            state.ordersIds = data || null;
          } else {
            state.paymentOuterLink = action.payload;
          }
        }
      },
    );

    builder.addCase(fetchPaymentThunkAction.rejected, (state, action) => {
      const { paymentMethod } = action.meta.arg;

      if (paymentMethod === 'btc-balance') {
        state.btcBalanceFetchStatus = 'rejected';
      } else if (paymentMethod === 'alpha-po' || paymentMethod === 'coinsbuy') {
        state.paymentCryptoFetchStatus = 'rejected';
      } else if (paymentMethod === 'mercuryo') {
        state.paymentCardFetchStatus = 'rejected';
      }

      state.error = JSON.stringify(action.error);
    });
  },
});

export const { resetPaymentLinks } = paymentSlice.actions;
export const paymentReducer = paymentSlice.reducer;
export const { setPaymentCategoryAction, clearPaymentStateAction } = paymentSlice.actions;
